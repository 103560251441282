import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  faSort,
  faPlus,
  faEye,
  faEdit,
  faTrash,
  faShareSquare,
  faShip,
  faPlane
} from "@fortawesome/free-solid-svg-icons";
import { faHome, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from "angular-bootstrap-md";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { canActive } from "../../common/helpers/authgouard";
declare var $: any;
import { GlobalConstants } from "../../common/helpers/global_variables";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { HttpProvider } from "../../common/http/http.provider";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SidenavComponent } from "../sidenav/sidenav.component";
@Component({
  selector: "app-shipment-moniter",
  templateUrl: "./shipment-moniter.component.html",
  styleUrls: ["./shipment-moniter.component.css"],
})
export class ShipmentMoniterComponent implements OnInit {
  title = "appBootstrap";
  showModel: any = false;
  showcargoDetailModal: any = false;
  submitButton: any = true;
  updateButton: any = false;
  registerForm: FormGroup;
  submitted = false;
  indexvalue: any = "";
  cargo: any = {
    trackno: "",
    status: "",
    averageShipingtime: "",
    shipmentNo: "",
    customerId: "",
  };
  model: NgbDateStruct;
  date: { year: number; month: number };
  dataEntry: any = [];
  closeResult: string;
  faSort = faSort;
  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
  faEye = faEye;
  faHome = faHome;
  faCaretRight = faCaretRight;
  faShareSquare = faShareSquare;
  faShip = faShip;
  faPlane = faPlane;
  navOpen: boolean = false;
  // @HostListener('input') oninput() {
  //   this.searchItems();
  // }
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  elements: any = [];
  cargoAirData: any = [];
  cargoOceanData: any = [];
  isDesc: boolean = false;
  column: string = "CategoryName";
  cargoElements: any = [];
  cargoItems: any = [];
  page = 1;

   pageSize = 10;
   numberOfElementsRecords:number;
   numberOfAirElements:number;
   numberOfOceanElements:number;
   cargoElementsPage = 1;
   cargoAirPage = 1;
   cargoOceanPage = 1;

  headElements = [
    "shipper information",
    "consignee information",
    "date",
    "tracking number",
    "status",
  ];
  searchText: string = "";
  previous: string;
  deleteoption: any = "";
  cargoheadElements1 = [
    "Pieces",
    "length",
    "weight",
    "height",
    "pounds",
    "cubic feet",
    "charge weight",
    "reference",
  ];
  // indexvalue = 5;
  constructor(
    private modalService: NgbModal,
    private _router: Router,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    private http: HttpProvider,
    private httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private component: SidenavComponent
  ) {
    // if (
    //   canActive("WareHouseUser") == false &&
    //   canActive("WareHouseSupervisor") == false
    // ) {
    //   let str = window.location.href;
    //   let myArr = str.split("#");
    //   window.location.replace(myArr[0] + "#/login");
    // }
    // this.deleteoption = canActive("WareHouseSupervisor") == true;
  }
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnInit(): void {
    GlobalConstants.cragoId = "";
    this.registerForm = this.formBuilder.group({
      status: ["", Validators.required],
      trackno: ["", Validators.required],
      shipmentno: ["", Validators.required],
      customer: ["", Validators.required],
      shipmentaveragetime: ["", Validators.required],
    });
    this.ngxService.start();
    this.getdata();
    // this.elements.push(this.dataEntry);

    // this.mdbTable.setDataSource(this.elements);
    // this.previous = this.mdbTable.getDataSource();
    // this.cdRef.detectChanges();
  }
  homeLink() {
    this.component.ngOnInit();
    if (localStorage.getItem("Role") == "Admin") {
      this._router.navigateByUrl("/adminDashboard");
    } else {
      this._router.navigateByUrl("/dashboard");
    }
  }
  searchItems() {
    console.log("serchitems");
    // const prev = this.mdbTable.getDataSource();
    // if (!this.searchText) {
    //   this.mdbTable.setDataSource(this.previous);
    //   this.elements = this.mdbTable.getDataSource();
    // }
    // if (this.searchText) {
    //   this.elements = this.mdbTable.searchLocalDataByMultipleFields(this.searchText, ['id', 'track no', 'status', 'average shiping time', 'shipment no', 'customer id']);
    //   this.mdbTable.setDataSource(prev);
    // }
  }
  //Cargo Detail modal open
  onViewItem(e, i) {
    GlobalConstants.cragoId = e.id;
    this.showcargoDetailModal = true;
    this.httpClient
      .get(this.http.BASE_URI + "editCargoEntry/" + e.id)
      .subscribe((response: any) => {
        //  console.log("Response", response.cargo_entry);
        this.cargoElements = response.cargo_entry;
        this.cargoItems = response.cargo_items;
        this.ngxService.stop();
      });
  }
  onRowCreate(e, i) {
    GlobalConstants.cragoId = e.id;
    GlobalConstants.cargoEntrySavebtn = false;
    GlobalConstants.cargoEntryUpdatebtn = true;
    this._router.navigateByUrl("/warehouse/cargoentry/air");
    // console.log("e====>", e, i);
  }
  onRowRemove(e, i) {
    // console.log(e);
    this.indexvalue = i;
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this data!",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        this.httpClient
          .delete(this.http.BASE_URI + "deleteCargo/" + e.id)
          .subscribe((response: any) => {
            // console.log("Response", response);
            this.ngxService.start();
            this.getdata();
          });
        Swal.fire("Deleted!", "Your data has been deleted.", "success");
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }
  onSubmitClick() {
    // console.log("onsubmit", this.cargo);
    this.submitted = true;
    // this.submitButton=false;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.elements.push(this.cargo);
    //  $("#mymodal").modal("hide");
    Swal.fire("Thank you...", "You submitted succesfully!", "success");
    this.showModel = false;
    //
    //  alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.cargo));
    //    this.cargo={
    //     trackno:"",
    //     status:"",
    //     averageShipingtime:new Date(),
    //     shipmentNo:"",
    //     customerId:""
    // }
    // display form values on success
  }
  onUpdateClick() {
    this.submitted = true;
    // this.submitButton=false;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.elements[this.indexvalue] = this.cargo;
    Swal.fire("Thank you...", "Your Data Edited Succesfully!", "success");
    this.showModel = false;
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.updateButton = false;
    this.submitButton = true;
  }
  aftersubmit() {
    $(".modal").modal("hide");
    // $("#myModal").modal("hide");
    Swal.fire("Thank you...", "You submitted succesfully!", "success");
  }
  getdata() {
    this.httpClient
      .get(
        this.http.BASE_URI +
          "customer/getCargoItems/" +
          localStorage.getItem("Id")
      )
      .subscribe((response: any) => {
        // console.log("Response", response);
        
          response.all.forEach((element) => {
          this.elements.push(element);
        });
        this.loadElementsPage(this.cargoElementsPage)
        console.log('elements'+response)
        
        this.cargoAirData = [];
        response.air.forEach((element) => {
          this.cargoAirData.push(element.details);
        });
        this.loadAirPage(this.cargoAirPage)
        this.cargoOceanData = [];
        response.ocean.forEach((element) => {
          this.cargoOceanData.push(element.details);
        });
        this.loadOceanPage(this.cargoOceanPage)
        console.log(this.cargoOceanData)
        // this.elements = response.air;
        this.ngxService.stop();
      });
  }
  configFormModel() {
    this.onReset();

    // this.showModel = true;
    GlobalConstants.cargoEntrySavebtn = true;
    GlobalConstants.cargoEntryUpdatebtn = false;
    this._router.navigateByUrl("/warehouse/cargoentry/air");
  }
  cancel() {
    this.showModel = false;
    this.showcargoDetailModal = false;
    // this.submitted = false;
    // this.ConfigFormGroup.reset();
  }
  sort(property) {
     console.log(property)
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    this.elements.sort(function (a, b) {
      a[property]=(a[property]==null)?" ":a[property]
      b[property]=(b[property]==null)?" ":b[property]
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  sort1(property) {
    console.log(property)
   this.isDesc = !this.isDesc; //change the direction    
   this.column = property;
   let direction = this.isDesc ? 1 : -1;
   this.cargoAirData.sort(function (a, b) {
     a[property]=(a[property]==null)?" ":a[property]
     b[property]=(b[property]==null)?" ":b[property]
     if (a[property] < b[property]) {
       return -1 * direction;
     }
     else if (a[property] > b[property]) {
       return 1 * direction;
     }
     else {
       return 0;
     }
   });
 };
 sort2(property) {
  console.log(property)
 this.isDesc = !this.isDesc; //change the direction    
 this.column = property;
 let direction = this.isDesc ? 1 : -1;
 this.cargoOceanData.sort(function (a, b) {
   a[property]=(a[property]==null)?" ":a[property]
   b[property]=(b[property]==null)?" ":b[property]
   if (a[property] < b[property]) {
     return -1 * direction;
   }
   else if (a[property] > b[property]) {
     return 1 * direction;
   }
   else {
     return 0;
   }
 });
};
   //showing pagination count
   loadElementsPage(page){
    this.numberOfElementsRecords = (page - 1) * this.pageSize + this.pageSize > this.elements.length ? this.elements.length :  (page - 1) * this.pageSize + this.pageSize;
  }
  loadAirPage(page){
    this.numberOfAirElements = (page - 1) * this.pageSize + this.pageSize > this.cargoAirData.length ? this.cargoAirData.length :  (page - 1) * this.pageSize + this.pageSize;
  }
  loadOceanPage(page){
    this.numberOfOceanElements = (page - 1) * this.pageSize + this.pageSize > this.cargoOceanData.length ? this.cargoOceanData.length :  (page - 1) * this.pageSize + this.pageSize;
  }
  air_ocean_shipmentChange(e, i) {
    GlobalConstants.cragoId = e.id;
    Swal.fire({
      title: "Are you sure change shipment type?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        this.ngxService.start();
        this.httpClient
          .post(this.http.BASE_URI + "shippingType", {
            cargo_id : e.id, 
            old_cargo_type : 'air' , 
            new_cargo_type : 'ocean',
            status: 'request', 
            sent_by_customer : localStorage.getItem("Id") 
          })
          .subscribe((response: any) => {
            this.getdata();
            this.ngxService.stop();
          });
        Swal.fire("", "Shipment type change request sent successfully", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
  ocean_to_airshipmentChange(e, i) {
    GlobalConstants.cragoId = e.id;
    Swal.fire({
      title: "Are you sure change shipment type?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        this.ngxService.start();
        this.httpClient
          .post(this.http.BASE_URI + "shippingType", {
            cargo_id : e.id, 
            old_cargo_type : 'ocean' , 
            new_cargo_type : 'air',
            status: 'request', 
            sent_by_customer : localStorage.getItem("Id") 
          })
          .subscribe((response: any) => {
            this.getdata();
            this.ngxService.stop();
          });
        Swal.fire("", "Shipment type change request sent successfully", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
}
